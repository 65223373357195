import React, { Fragment } from "react"
import "./PortfolioPage.css"
import Header from "../layout/Header"
import scrollWhite from "../../images/scrollWhite.svg"

const PortfolioPage = ({ pageContext }) => {
  const { acf } = pageContext
  return (
    <Fragment>
      <Header productPage={true} />
      <div
        className="projectPageHeader"
        style={{
          backgroundImage: `url(${pageContext["featured_media"].source_url})`
        }}
      >
        <div className="portfolioSummary">
          {acf.partner_logo ? (
            <img
              src={acf.partner_logo.source_url}
              alt="Partner Logo"
              className="partnerLogo"
            />
          ) : null}

          <div className="leftSummary">
            <h4 className="clientTag">client</h4>
            <h1 className="projectTitle">{pageContext.title}</h1>
            <h2 className="projectStrapline">{acf.strapline}</h2>
          </div>
          <div className="summaryDivider" />
          <div className="rightSummary">
            <div
              className="excerpt"
              dangerouslySetInnerHTML={{ __html: pageContext.excerpt }}
            />
          </div>
        </div>
        <img className="scrollIcon" alt="scroll" src={scrollWhite} />
      </div>
      <section className="projectPage">
        <h1 className="fs-30 fw-600 whiteFont">Project Description</h1>
        <div className="dividerWhite"></div>
        <div className="projectPageContainer">
          <div className="projectDescription">
            <h3 className="projectHeadline">The Partner</h3>
            <div className="smallDividerBlack" />
            <p
              className="mb-3 mt-2"
              dangerouslySetInnerHTML={{ __html: acf.the_partner }}
            ></p>
            <h3 className="projectHeadline">The Problem</h3>
            <div className="smallDividerBlack" />
            <p
              className="mb-3 mt-2"
              dangerouslySetInnerHTML={{ __html: acf.the_problem }}
            ></p>
            <h3 className="projectHeadline">The Solution</h3>
            <div className="smallDividerBlack" />
            <p
              className="mb-3 mt-2"
              dangerouslySetInnerHTML={{ __html: acf.the_solution }}
            ></p>
          </div>
          <div className="projectPictures">
            <img
              src={acf.first_content_picture.source_url}
              alt="showcase"
              className="projectPicture"
            />
            <img
              src={acf.second_content_picture.source_url}
              alt="showcase"
              className="projectPicture"
            />
            <img
              src={acf.third_content_picture.source_url}
              alt="showcase"
              className="projectPicture"
            />
          </div>
        </div>
      </section>
    </Fragment>
  )
}

export default PortfolioPage
//
